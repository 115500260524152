import "./bootstrap.min.css";
import "./custom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const SHIFT_KEY = 16;
const SPACE_KEY = 32;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;
const P_KEY = 80;

function App() {
  const [isShiftHeld, setIfShiftHeld] = useState(false);

  const videoBaseUrl = "https://vsapi.proscore.soccer/video/stream/";

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (!urlParams.has("id")) {
    alert("No video provided");
  }

  const date = urlParams.has("date") ? urlParams.get("date") : "";
  const ageGroup = urlParams.has("ageGroup") ? urlParams.get("ageGroup") : "";
  const homeTeam = urlParams.has("homeTeam") ? urlParams.get("homeTeam") : "";
  const awayTeam = urlParams.has("awayTeam") ? urlParams.get("awayTeam") : "";

  const videoSource = urlParams.has("id")
    ? videoBaseUrl + urlParams.get("id")
    : "";

  // calculate the videoUrl....
  useEffect(() => {
    const videoJsOptions = {
      // lookup the options in the docs for more options
      sources: [
        {
          src: videoSource,
          type: "application/x-mpegURL",
        },
      ],
      controls: true,
      preload: "auto",
      playbackRates: [0.5, 1],
      pictureInPictureToggle: false,
      //disablePictureInPicture: false,
      currentTimeDisplay: true,
      timeDivider: false,
      durationDisplay: false,
      inactivityTimeout: 0,
    };

    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          //set time
          if (urlParams.has("t")) {
            let time = Number.parseInt(urlParams.get("t"));
            if (time && !isNaN(time) && time > 0) {
              playerRef.current.currentTime(time);
            }
          }
        }
      ));
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [videoSource]);

  useEffect(() => {
    let handleKeyDown = (event) => {
      switch (event.keyCode) {
        case SHIFT_KEY:
          setIfShiftHeld(true);
          break;
        case LEFT_ARROW_KEY:
          if (isShiftHeld)
            playerRef.current.currentTime(playerRef.current.currentTime() - 10);
          else
            playerRef.current.currentTime(playerRef.current.currentTime() - 5);
          break;
        case RIGHT_ARROW_KEY:
          if (isShiftHeld)
            playerRef.current.currentTime(playerRef.current.currentTime() + 10);
          else
            playerRef.current.currentTime(playerRef.current.currentTime() + 5);
          break;
        case SPACE_KEY:
          if (playerRef.current.paused()) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
          break;
        default:
          break;
      }
    };

    let handleKeyUp = (event) => {
      switch (event.keyCode) {
        case SHIFT_KEY:
          setIfShiftHeld(false);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  return (
    <>
      <nav
        className="navbar navbar-expand-lg"
        style={{
          padding: "0.5rem",
          backgroundPosition: "left",
          backgroundImage: "url('nav-background.webp')",
        }}
      >
        <div className="container">
          <img
            alt="logo"
            className="navbar-brand"
            src="proscore-primary-white.png"
          />
          <h2 className="text-white">Video Player</h2>
          <img
            src="https://images.mlssoccer.com/image/upload/v1664742553/assets/logos/mls-next-2022-COLOR-800x800.png"
            alt="MLS Next"
            height={64}
          />
        </div>
      </nav>

      {/* {showLoader && 
        <div id="loader" className="loader">
            <FontAwesomeIcon icon={faSpinner} className="spinner"/>
        </div>
      } */}

      <div class="d-inline-flex justify-content-center w-100 text-white">
        {date && <h2 class="m-4">{date}</h2>}
        {ageGroup && <h2 class="m-4">{ageGroup}</h2>}
        {homeTeam && <h2 class="m-4">{homeTeam}</h2>}
        {homeTeam && awayTeam && <h2 class="m-4">-</h2>}
        {awayTeam && <h2 class="m-4">{awayTeam}</h2>}
      </div>

      <div
        id="video-wrapper"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div data-vjs-player style={{ height: "720px", width: "1080px" }}>
          <video
            ref={videoRef}
            className="video-js vjs-big-play-centered vjs-default-skin"
          />
        </div>
      </div>
    </>
  );
}

export default App;
